import { showGlobalLoaderType, hideGlobalLoaderType } from './NavMenu';

const changeCurrentFiltersType = "CHANGE_CURRENT_GP_DATA_FILTERS";
const changeCurrentSearchType = "CHANGE_CURRENT_GP_DATA_SEARCH";
const changeCurrentSortingType = "CHANGE_CURRENT_GP_DATA_SORTING";

const initialState = {
    gpName: null,
    filters: [],
    search: "",
    sorting: [{ column: "code", dir: "asc" }]
};

export const actionCreators = {
    showLoader: () => ({ type: showGlobalLoaderType }),
    stopLoader: () => ({ type: hideGlobalLoaderType }),
    changeFilters: (filters) => (dispatch, getState) => {
        dispatch({ type: changeCurrentFiltersType, filters });
    },
    changeSearch: (search) => (dispatch, getState) => {
        dispatch({ type: changeCurrentSearchType, search });
    },
    changeSorting: (sorting) => (dispatch, getState) => {
        dispatch({ type: changeCurrentSortingType, sorting });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === changeCurrentFiltersType) {
        return {
            ...state,
            filters: action.filters
        };
    }

    if (action.type === changeCurrentSortingType) {
        return {
            ...state,
            sorting: action.sorting
        };
    }

    if (action.type === changeCurrentSearchType) {
        return {
            ...state,
            search: action.search
        };
    }

    return state;
};

