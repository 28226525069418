import { showGlobalLoaderType, hideGlobalLoaderType, showSnackBarType, hideGlobalSaveType, showGlobalSaveType } from './NavMenu';

const initialState = {
};

export const actionCreators = {
    showLoader: () => ({ type: showGlobalLoaderType }),
    stopLoader: () => ({ type: hideGlobalLoaderType }),
    showSnackBar: (message, type) => ({ type: showSnackBarType, message, snackBarType: type }),
    stopSaving: () => ({ type: hideGlobalSaveType }),
    showSaving: () => ({ type: showGlobalSaveType }),
};

export const reducer = (state, action) => {
    state = state || initialState;
    return state;
};
