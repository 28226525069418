import HttpUtil from '../util/HttpUtil';
import { showGlobalLoaderType, hideGlobalLoaderType, showSnackBarType, showGlobalSaveType, hideGlobalSaveType, changeMailStatusType } from './NavMenu';

const fetchSettingsType = 'FETCH_SETTINGS';
const setFieldType = 'SET_SETTINGS_FIELD';
const startSaveType = 'START_SETTINGS_SAVE';
const saveType = 'SETTINGS_SAVE';

const initialState = {
    settings: {},
    isLoading: true,
    isSaving: false
};

export const actionCreators = {
    showLoader: () => ({ type: showGlobalLoaderType }),
    stopLoader: () => ({ type: hideGlobalLoaderType }),
    fetch: () => async (dispatch, getState) => {
        var data = await HttpUtil.fetchGetAsync('api/Settings');
        dispatch({ type: fetchSettingsType, settings: data.settings });
    },
    setField: (field, value) => async (dispatch, getState) => {
        dispatch({ type: setFieldType, field, value });
    },
    saveSettings: () => async (dispatch, getState) => {
        dispatch({ type: showGlobalSaveType });
        dispatch({ type: startSaveType });
        try {
            var settingsData = await HttpUtil.fetchAsync('api/Settings', getState().settings.settings, "POST");
            if (settingsData.result === "OK") {
                dispatch({ type: showSnackBarType, message: "Изменения успешно сохранены!", snackBarType: "success" });
            } else {
                throw new Error(JSON.stringify(settingsData));
            }
        } catch (err) {
            dispatch({ type: showSnackBarType, message: "Произошла ошибка сохранения настроек: " + err });
        } finally {
            dispatch({ type: hideGlobalSaveType });
            dispatch({ type: saveType });
        }
    },
    changeMailData: (mailData) => ({ type: changeMailStatusType, mailData }),
    showSnackBar: (message, type) => ({ type: showSnackBarType, message, snackBarType: type }),
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === setFieldType) {
        return {
            ...state,
            settings: {
                ...state.settings,
                [action.field]: action.value
            }
        }
    }

    if (action.type === fetchSettingsType) {
        return {
            ...state,
            isLoading: false,
            settings: action.settings
        }
    }

    if (action.type === startSaveType) {
        return {
            ...state,
            isSaving: true
        }
    }

    if (action.type === saveType) {
        return {
            ...state,
            isSaving: false
        }
    }

    return state;
};

