import HttpUtil from "../util/HttpUtil";
import { hidePreScreenType, showGlobalLoaderType, hideGlobalLoaderType, checkLoginType} from "./NavMenu";

const requestLoginType = "REQUEST_LOGIN";
const receiveLoginType = "RECEIVE_LOGIN";
const focusLoginType = "FOCUS_LOGIN";
const focusPasswordType = "FOCUS_PASSWORD";
const closeDialogType = "CLOSE_DIALOG";

const initialState = {
    isLoading: false,
    openRestoreDia: false
};

export const actionCreators = {
    hideGlobalLoader: () => ({ type: hidePreScreenType }),
    stopLoader: () => ({ type: hideGlobalLoaderType }),
    showLoader: () => ({ type: showGlobalLoaderType }),
    requestLogin: (email, password) => async (dispatch, getState) => {
        if (email == null || password == null) {
            dispatch({
                type: receiveLoginType,
                error: "Логин и пароль являются обязательными полями"
            });
            return;
        }

        if (email.trim() === "") {
            dispatch({
                type: receiveLoginType,
                loginError: "Введите логин"
            });
            return;
        }
        
        if (password === "") {
            dispatch({
                type: receiveLoginType,
                passwordError: "Введите пароль"
            });
            return;
        }

        dispatch({ type: requestLoginType });
        dispatch({ type: showGlobalLoaderType });

        try {
            var data = await HttpUtil.fetchGetAsync("api/Login/SignIn", { login: email, password });
            dispatch({ type: hideGlobalLoaderType });
            if (data.accessToken) {
                localStorage.setItem("accessToken", data.accessToken);
                localStorage.setItem("role", data.profile.role);
                localStorage.setItem("refreshToken", data.refreshToken);
                localStorage.setItem("expires", data.expires);
                dispatch({
                    type: checkLoginType,
                    user: data.profile,
                    isLogin: true
                });
            }
            dispatch({
                type: receiveLoginType,
                error: data.errorDescription === "invalid_username_or_password" ||
                    (data.errorDescription === null && data.error !== null) ?
                        "Предъявлены неверные данные для входа в Систему" :
                        data.errorDescription
            });
        } catch (err) {
            dispatch({
                type: receiveLoginType,
                error: err.message
            })
        }
    },
    focusLogin: () => ({ type: focusLoginType }),
    focusPassword: () => ({ type: focusPasswordType }),
    closeDialog: () => ({ type: closeDialogType }),
};


export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case closeDialogType:
            return Object.assign({}, state, {
                error: undefined
            });
        case focusLoginType:
            return Object.assign({}, state, {
                loginError: undefined
            });
        case focusPasswordType:
            return Object.assign({}, state, {
                passwordError: undefined
            });
        case requestLoginType:
            return Object.assign({}, state, {
                isLoading: true
            });
        case receiveLoginType:
            return Object.assign({}, state, {
                error: action.error,
                loginError: action.loginError,
                passwordError: action.passwordError,
                isLoading: false
            });
        default:
            return state;
    }
};
