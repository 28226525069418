import { showGlobalLoaderType, hideGlobalLoaderType, showSnackBarType, hideDrawerType, showGlobalSaveType, hideGlobalSaveType } from './NavMenu';
import HttpUtil from '../util/HttpUtil';
import { EditorState, convertFromRaw } from 'draft-js';

export const changeGpInfoType = "CHANGE_GP_INFO";
export const fetchGpInfoType = "FETCH_GP_INFO";

const initialState = {
    text: ""
};


export const actionCreators = {
    showLoader: () => ({ type: showGlobalLoaderType }),
    stopLoader: () => ({ type: hideGlobalLoaderType }),
    hideDrawer: () => ({ type: hideDrawerType }),
    stopSaving: () => ({ type: hideGlobalSaveType }),
    showSaving: () => ({ type: showGlobalSaveType }),
    showSnackBar: (message, type) => ({ type: showSnackBarType, message, snackBarType: type }),
    fetch: () => (dispatch, getState) => {
        var data = HttpUtil.fetchGetAsync("api/GpInforming");
        console.log(data);
        var currentValue = data.gpInforming.text;
        var content = convertFromRaw(JSON.parse(currentValue));
        var editorState = EditorState.createWithContent(content);
        dispatch({ type: fetchGpInfoType, text: editorState });
    }
};

export const reducer = (state = initialState, action) => {

    if (action.type === fetchGpInfoType) {
        return {
            text: action.text
        }
    }

    return state;
};
