import { showGlobalLoaderType, hideGlobalLoaderType } from './NavMenu';

const setFiltersType = 'SET_FILTERS';

const initialState = {
    filters: []
};


export const actionCreators = {
    showLoader: () => ({ type: showGlobalLoaderType }),
    stopLoader: () => ({ type: hideGlobalLoaderType }),
    setFilters: (filters) => async (dispatch, getState) => {
        dispatch({ type: setFiltersType, filters });
    },
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
    case setFiltersType:
        return {
            ...state,
            filters: action.filters
        };
    default:
        return state;
    }
};