import React from 'react';
import { Route, Switch } from 'react-router';
import asyncComponent from './components/const/AsyncComponent';

const Layout = asyncComponent(() => import("./components/Layout"));
const Login = asyncComponent(() => import("./components/Login"));
const Roles = asyncComponent(() => import("./components/Roles"));
const RoleEdit = asyncComponent(() => import("./components/RoleEdit"));
const Users = asyncComponent(() => import("./components/Users"));
const UserEdit = asyncComponent(() => import("./components/UserEdit"));
const AccessForbidden = asyncComponent(() => import("./components/AccessForbidden"));
const Settings = asyncComponent(() => import("./components/Settings"));
const InDevelop = asyncComponent(() => import("./components/InDevelop"));
const DictionaryEdit = asyncComponent(() => import("./components/DictionaryEdit"));
const Dictionary = asyncComponent(() => import("./components/Dictionary"));
const DictionaryList = asyncComponent(() => import("./components/DictionaryList"));
const ImportSuppliers = asyncComponent(() => import("./components/ImportSuppliers"));
const InputRules = asyncComponent(() => import("./components/InputRules"));
const RuleEdit = asyncComponent(() => import("./components/RuleEdit"));
const SupplierEdit = asyncComponent(() => import("./components/SupplierEdit"));
const LoginLogs = asyncComponent(() => import("./components/LoginLogs"));
const MailLogs = asyncComponent(() => import("./components/MailLogs"));
const ExportLogs = asyncComponent(() => import("./components/ExportLogs"));
const ChangeLogs = asyncComponent(() => import("./components/ChangeLogs"));
const ReportDebts = asyncComponent(() => import("./components/ReportDebts"));
const ProvidingInformation = asyncComponent(() => import("./components/ProvidingInformation"));
const ProvisionContracts = asyncComponent(() => import("./components/ProvisionContracts"));
const GeneralInformation = asyncComponent(() => import("./components/GeneralInformation"));
const ReportResults = asyncComponent(() => import("./components/ReportResults"));
const ProvisionNotifications = asyncComponent(() => import("./components/ProvisionNotifications"));
const GpData = asyncComponent(() => import("./components/GpData"));
const GpDataForms = asyncComponent(() => import("./components/GpDataForms"));
const GpDataFormView = asyncComponent(() => import("./components/GpDataFormView"));
const GpInforming = asyncComponent(() => import("./components/GpInforming"));
const ExtMainPreview = asyncComponent(() => import("./components/ExtMainPreview"));
const TermEdit = asyncComponent(() => import("./components/TermEdit"));
const TaskSettings = asyncComponent(() => import("./components/TaskSettings"));
const TaskSettingsEdit = asyncComponent(() => import("./components/TaskSettingsEdit"));
const MyTasks = asyncComponent(() => import("./components/MyTasks"));
const Notifications = asyncComponent(() => import("./components/Notifications"));
const NotificationEdit = asyncComponent(() => import("./components/NotificationEdit"));
const Mailings = asyncComponent(() => import("./components/Mailings"));
const MailingEdit = asyncComponent(() => import("./components/MailingEdit"));
const BrowserCheck = asyncComponent(() => import("./components/BrowserCheck"));

export default () => (
    <Layout>
        <Switch>
            <Route path='/browser_check' component={BrowserCheck} />
            <Route path='/login' component={Login} />
            <Route path='/users' component={Users} />
            <Route path='/user/:type/:id?' component={UserEdit} />
            <Route path='/roles' component={Roles} />
            <Route path='/role/:type/:id?' component={RoleEdit} />
            <Route path='/task_settings' component={TaskSettings} />
            <Route path='/task_setting/:id?' component={TaskSettingsEdit} />
            <Route path='/access_forbidden' component={AccessForbidden} />
            <Route path='/settings' component={Settings} />
            <Route path='/logs_change' component={ChangeLogs} />
            <Route path='/logs_login' component={LoginLogs} />
            <Route path='/logs_export' component={ExportLogs} />
            <Route path='/logs_mail' component={MailLogs} />
            <Route path='/mailings' component={Mailings} />
            <Route path='/mailing/:type/:id?' component={MailingEdit} />
            <Route path='/notifications' component={Notifications} />
            <Route path='/notification/:id' component={NotificationEdit} />
            <Route path='/providing_information' component={ProvidingInformation} />
            <Route path='/general_information' component={GeneralInformation} />
            <Route path='/provision_notifications' component={ProvisionNotifications} />
            <Route path='/provision_contracts' component={ProvisionContracts} />
            <Route path='/report_1' component={ReportDebts} />
            <Route path='/report_2' component={ReportResults} />
            <Route path='/rules' component={InputRules} />
            <Route path='/rule/:type/:id?' component={RuleEdit} />
            <Route path='/gp_informing' component={GpInforming} />
            <Route path='/ext_main_preview' component={ExtMainPreview} />
            <Route path='/gp_data' component={GpData} />
            <Route path='/gp_data_forms/:id?' component={GpDataForms} />
            <Route path='/gp_data_form/:form/:id?' component={GpDataFormView} />
            <Route path='/analysis' component={InDevelop} />
            <Route path='/tasks' component={MyTasks} />
            <Route path='/dictionaries' component={DictionaryList} />
            <Route path='/dictionary/:name/view' component={Dictionary} />
            <Route path='/dictionary/Supplier/:type/:id?' component={SupplierEdit} />
            <Route path='/dictionary/ProvisionTerm/:type/:id?' component={TermEdit} />
            <Route path='/dictionary/AttributionTerm/:type/:id?' component={TermEdit} />
            <Route path='/dictionary/:name/:type/:id?' component={DictionaryEdit} />
            <Route path='/' exact component={Users} />
            <Route path='/importSuppliers' component={ImportSuppliers} />
        </Switch>
    </Layout>
);