import { showGlobalLoaderType, hideGlobalLoaderType } from './NavMenu';

const initialState = {
};


export const actionCreators = {
    showLoader: () => ({ type: showGlobalLoaderType }),
    stopLoader: () => ({ type: hideGlobalLoaderType })
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        default:
            return state;
    }
};